* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
html, body { 
  min-height: 100vh;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
} 
body {margin: 0; padding: 0;}

body {
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -moz-osx-font-smoothing: grayscale;
  /* background-image: url('https://source.unsplash.com/random/1600x900');
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover; */

/* #2196f3 */
/* #42a5f5 */
/* 4dabf5 */

  background-color: #33adff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
